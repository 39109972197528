import React from "react"

import { ButtonBase, ButtonStyle } from "./styles"

export default function Button({ children, type = "button", ...props }) {
  return (
    <>
      <ButtonBase />
      <ButtonStyle as={props.href ? "a" : "button"} type={type} {...props}>
        {children}
      </ButtonStyle>
    </>
  )
}
