import React from "react"
// import { Link } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/Layout"

import { Container, Row, Col } from "../components/Grid"

import Intro from "../components/Intro"
import Text from "../components/Text"
import Project from "../components/Project"

import Icon from "../images/undraw_to_the_moon_v1mv.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Introdução" />

    <Intro
      full={true}
      title="Olá mundo!"
      description="Meu nome é Bruno Henrique Saibert, sou desenvolvedor full-stack."
      img={{
        src: Icon,
        alt: "",
      }}
    />

    <Container full>
      <Row alignItems="center">
        <Col size="12">
          <Text as="h2" color="cloud_light" mt="30" mb="30" align="center">
            Atualmente estou trabalhando no seguinte projeto
          </Text>
          <Project
            hrefDemo="https://brunoflix.netlify.app/"
            hrefRepo="https://github.com/BrunoSaibert/brunoflix"
            url="brunoflix/brunoflix.jpg"
            title="Brunoflix Web"
            tags={["ReactJS", "TypeScript", "Styled components"]}
            description={
              <>
                <Text mb="15" color="cloud">
                  O projeto tem a finalidade de desenvolver um clone da
                  interface da Netflix utilizando uma API externa para listar os
                  filmes e carregar o trailer.
                </Text>
              </>
            }
          />
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
