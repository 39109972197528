import styled from "styled-components"

export const Project = styled.div`
  background: ${props => props.theme.color.shadow_dark};
  border-radius: 5px;
  /* overflow: hidden; */
  letter-spacing: 1px;
  padding: 20px;
  margin: 30px 0;

  display: flex !important;
  /* align-items: center; */
  justify-content: space-between;

  @media (max-width: ${props => props.theme.grid.breakpoint.md}px) {
    flex-direction: column;
  }

  & > * {
    flex: 50%;
    margin: 10px;
    border-radius: 5px;
  }
`
