import React from "react"

import Text from "../Text"
import Button from "../Button"
import Image from "../Image"
import Tag from "../Tag"

import * as S from "./styles"

export default function Project({
  hrefDemo,
  hrefRepo,
  url,
  title,
  description,
  tags,
}) {
  return (
    <S.Project>
      {url && <Image filename={url} alt="" />}

      <div>
        {title && (
          <Text as="h3" mb="15" color="cloud_light">
            {title}
          </Text>
        )}

        {description}

        {tags && tags.map(tag => <Tag key={tag}>{tag}</Tag>)}

        <Button href={hrefDemo} target="blank">
          Demonstração
        </Button>
        <Button href={hrefRepo} target="blank">
          Repositório
        </Button>
      </div>
    </S.Project>
  )
}
