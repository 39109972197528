import styled, { createGlobalStyle } from "styled-components"

export const ButtonBase = createGlobalStyle`
  .btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    border: 0;
    border-radius: 2px;
    height: 40px;
    max-width: 200px;
    padding: 0 32px;
    margin: 10px 0;
    font-weight: ${props => props.theme.font.weight.semiBold};
    transition: all 0.4s;
    background: ${props => props.theme.color.primary};
    text-decoration: none;

    @media (max-width: ${props => props.theme.grid.breakpoint.md}px) {
      max-width: 100%;
    }

    &:hover{
      background: ${props => props.theme.color.primary_light};
    }
  }
`

export const ButtonStyle = styled.button.attrs({
  className: "btn",
})`
  color: ${props => props.theme.color.light} !important;

  @media (max-width: ${props => props.theme.grid.breakpoint.sm}px) {
    margin: 0 auto;
  }
`
