import styled from "styled-components"

export const TagStyle = styled.div`
  background: ${props => props.theme.color.cloud};
  color: ${props => props.theme.color.dark};
  border-radius: 20px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 2px 16px;
  font-weight: ${props => props.theme.font.weight.semiBold};
  font-size: ${props => props.theme.font.size.xs[1]};
  line-height: ${props => props.theme.font.size.xs[1]};

  margin-right: 5px;
  margin-bottom: 15px;
`
