import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { ImageWrapper } from "./styles"

export default function Image({ filename, alt }) {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: { absolutePath: {} }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 700, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const image = data.images.edges.find(n => {
    return n.node.relativePath.includes(filename)
  })

  console.log("image", image)

  return (
    <>
      {image && (
        <ImageWrapper fluid={image.node.childImageSharp.fluid} alt={alt} />
      )}
    </>
  )
}
